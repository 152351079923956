import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Button, Checkbox, Dropdown, Input, Message, Modal, Radio } from "semantic-ui-react";

// Common
import SearchBox from "react-lib/appcon/common/SearchBox";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import CONFIG from "config/config";
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";
import moment from "moment";
import { useIntl } from "react-intl";

// Types
type CardBillingReportProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: (params: Record<string, any>) => any;
  billingHistorySequence?: Record<string, any>;
  // data
  constanceConfig: Record<string, any>;
  // CommonInterface
  errorMessage?: any;
  buttonLoadCheck?: Record<string, any>;
  searchedItemList?: any[];
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: Record<string, any>;
};

type ReportTypesKeys = keyof typeof REPORT_TYPES;

type ReportTypesValues = (typeof REPORT_TYPES)[ReportTypesKeys] | "";

type DisplayFilterType = {
  type: boolean;
  coverage: boolean;
  station: boolean;
  shift: boolean;
  receipt: boolean;
  staff: boolean;
  hn: boolean;
};

// Const
export const BILL_STATUS = [
  {
    key: 0,
    value: 0,
    text: "ทุกสถานะ",
  },
  { key: 1, value: 1, text: "PAID" },
  { key: 2, value: 2, text: "CANCELED" },
  { key: 3, value: 3, text: "REPLACED" },
];

const BILL_PATIENT_TYPE: any[] = [
  { key: 0, value: 0, text: "OPD และ IPD" },
  { key: "OPD", value: "OPD", text: "OPD" },
  { key: "IPD", value: "IPD", text: "IPD" },
];

const BUTTON_ACTIONS = {
  PREVIEW: "PREVIEW",
  PRINT: "PRINT",
  XLSX: "XLSX",
} as const;

const TRACKING_STATUS = {
  CANCELED: "CANCELED",
  ERROR: "ERROR",
} as const;

const REPORT_TYPES = {
  DRAWER_CASH: "รายงาน Drawer Cash",
  // DAILY_REVENUE: "รายงานรายได้ประจำวันรวม",
  DAILY_UNPAID: "รายงานค่ารักษาพยาบาลค้างชำระ",
  APPROVAL_CODE: "รายการรอปรับปรุง Approval Code",
  DRAWER_COVERAGE: "รายงาน Drawer Coverage",
  DAILY_CASH: "รายงานรายได้ประจำวันประเภทเงินสด",

  DAILY_APPROVED_UNPERFORMED: "รายงานผ่านสิทธิไม่รับบริการ",
  IPD_DISCHARGE: "รายงานผู้ป่วย Discharge",
  REMITTANCE: "รายงานใบนำส่งเงิน",
  DAILY_COVERAGE: "รายงานรายได้ประจำวันประเภทเงินเชื่อ",
  DAILY_APPROVED: "รายงานการผ่านสิทธิผ่านเครื่อง EDC",

  INSTALLMENT: "รายงานผ่อนชำระ",
  INCOME: "รายงานรายได้ที่บันทึกรับชำระเงินแล้ว",
  MEDICAL_FEE: "รายงานค่ารักษาพยาบาลแยกสิทธิ",
  CASHIER_WORK: "รายงานการปฏิบัติงานของเจ้าหน้าที่",
  COPY_RECEIPT: "รายงานการพิมพ์สำเนาใบเสร็จ",
  DEPOSIT_REPORT: "รายงานรับเงินมัดจำ",
  USED_DEPOSIT_REPORT: "รายงานรายละเอียดการใช้เงินมัดจำ",
  RECEIPT_RETURN_REPORT: "รายงานการ Return ใบเสร็จ",
} as const;

const defaultReportButtonList = [
  REPORT_TYPES.DRAWER_CASH,
  REPORT_TYPES.INCOME,
  REPORT_TYPES.DAILY_UNPAID,
  REPORT_TYPES.APPROVAL_CODE,
  REPORT_TYPES.DRAWER_COVERAGE,
  REPORT_TYPES.DAILY_CASH,
  REPORT_TYPES.DAILY_APPROVED_UNPERFORMED,
  REPORT_TYPES.IPD_DISCHARGE,
  REPORT_TYPES.REMITTANCE,
  REPORT_TYPES.DAILY_COVERAGE,
  REPORT_TYPES.DAILY_APPROVED,
  REPORT_TYPES.INSTALLMENT,
  REPORT_TYPES.MEDICAL_FEE,
  REPORT_TYPES.CASHIER_WORK,
  REPORT_TYPES.COPY_RECEIPT,
  REPORT_TYPES.DEPOSIT_REPORT,
  REPORT_TYPES.USED_DEPOSIT_REPORT,
  REPORT_TYPES.RECEIPT_RETURN_REPORT,
];

const CARD_BILLING_REPORT = "CardBillingReport";

const DEFAULT_DATE = dateToStringWithoutTimeBE(moment());

const startDateLabel = (type: ReportTypesValues) => {
  if (
    (
      [
        REPORT_TYPES.DRAWER_COVERAGE,
        REPORT_TYPES.DRAWER_CASH,
        REPORT_TYPES.CASHIER_WORK,
        REPORT_TYPES.USED_DEPOSIT_REPORT,
      ] as ReportTypesValues[]
    ).includes(type)
  )
    return "วันที่";

  if (([REPORT_TYPES.DAILY_UNPAID, REPORT_TYPES.INCOME] as ReportTypesValues[]).includes(type))
    return "วันที่ค่าใช้จ่ายเริ่มต้น";

  if (([REPORT_TYPES.REMITTANCE] as ReportTypesValues[]).includes(type))
    return "วันที่สรุปยอดปิดกะ";

  if (([REPORT_TYPES.MEDICAL_FEE] as ReportTypesValues[]).includes(type))
    return "วันที่รับชำระเงิน";

  if (([REPORT_TYPES.DEPOSIT_REPORT] as ReportTypesValues[]).includes(type)) return "ช่วงวันที่";

  return "เริ่มต้น";
};

const CardBillingReport = (props: CardBillingReportProps) => {
  const intl = useIntl();
  const [tracking, setTracking] = useState(false);
  const [startDate, setStartDate] = useState(DEFAULT_DATE); // issue 67978 set start date and end date as current date
  const [endDate, setEndDate] = useState(DEFAULT_DATE);
  const [station, setStation] = useState(0);
  const [shift, setShift] = useState(0);
  const [coverage, setCoverage] = useState(0);
  const [patientType, setPatientType] = useState(0);
  const [type, setType] = useState<ReportTypesValues>("");
  const [dischargeOnly, setDischargeOnly] = useState(false);
  const [payer, setPayer] = useState("");
  const [receiptStatus, setReceiptStatus] = useState(0);
  const [typeReport, setTypeReport] = useState("DAILY");
  const [staff, setStaff] = useState(null);
  const [hn, setHN] = useState("");

  // Error
  const [openMessage, setOpenMessage] = useState(false);

  const [displayFilter, setDisplayFilter] = useState<Partial<DisplayFilterType>>({});

  // Use Effect
  useEffect(() => {
    props.runSequence({
      sequence: "BillingHistory",
      restart: true,
    });
  }, []);

  useEffect(() => {
    props.runSequence({
      sequence: "BillingHistory",
      action: [
        REPORT_TYPES.CASHIER_WORK,
        REPORT_TYPES.DEPOSIT_REPORT,
        REPORT_TYPES.USED_DEPOSIT_REPORT,
      ].includes(type)
        ? "get_bilUser"
        : "get_bilStationLog",
      station: station === 0 ? "" : station,
      start_date: DEFAULT_DATE,
      end_date: DEFAULT_DATE,
      type: type,
    });
  }, [type]);

  // Memo Effect
  const trackingStatus = useMemo(() => {
    if (props.billingHistorySequence?.trackingStatus != null) {
      return props.billingHistorySequence?.trackingStatus;
    }
  }, [props.billingHistorySequence?.trackingStatus]);

  useEffect(() => {
    if (trackingStatus != null && trackingStatus?.status !== TRACKING_STATUS.CANCELED) {
      setTracking(true);
    }

    if (["WAITING", "IN_PROGRESS"].includes(trackingStatus?.status || "")) {
      setTimeout(() => {
        props.runSequence({
          sequence: "BillingHistory",
          action: "set_tracking_print",
          task_id: trackingStatus.task_id,
          pdf: trackingStatus.pdf,
        });
      }, 5000);
    }
  }, [trackingStatus]);

  const handleSelectedItem = useCallback(
    async (value: any, key: any, obj: any) => {
      let user = props.searchedItemListWithKey?.[`User_1`]?.find((item: any) => item.id === key);

      if (!user?.id) {
        props.setProp(`searchedItemListWithKey.User_1`, []);
      }

      setStaff(user?.id || null);
    },
    [props.searchedItemListWithKey]
  );

  // Use Memo
  const bilCoverageOptions = useMemo(() => {
    return [{ key: 0, value: 0, text: "ทุก coverage" }, ...(props.masterOptions?.coverage || [])];
  }, [props.masterOptions?.coverage]);

  const bilStationOptions = useMemo(() => {
    return [{ key: 0, value: 0, text: "ทุก station" }, ...(props.masterOptions?.bilStation || [])];
  }, [props.masterOptions?.bilStation]);

  const bilStationLogOptions = useMemo(() => {
    return (props.billingHistorySequence?.bilStationLog || []).map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.full_name ?? item.name,
    }));
  }, [props.billingHistorySequence?.bilStationLog]);

  const filteredReportButtonList = useMemo(() => defaultReportButtonList.filter((i) => {
      const hideBillingReports = (CONFIG.HIDE_BILLING_REPORT || []).map(
        (h: any) => REPORT_TYPES[h]
      ) as string[];

      const shouldHideReceiptReturn =
        props.constanceConfig.BIL_AUTO_RUN_RECEIPT &&
        i === REPORT_TYPES.RECEIPT_RETURN_REPORT;

      return !hideBillingReports.includes(i) && !shouldHideReceiptReturn;
    }), [
    defaultReportButtonList,
    props.constanceConfig.BIL_AUTO_RUN_RECEIPT,
  ]);

  const reportShow = (type: any) => {
    setType(type);

    setDisplayFilter({
      coverage: [
        REPORT_TYPES.INCOME,
        REPORT_TYPES.IPD_DISCHARGE,
        REPORT_TYPES.INSTALLMENT,
        REPORT_TYPES.DAILY_UNPAID,
        REPORT_TYPES.IPD_DISCHARGE,
        REPORT_TYPES.COPY_RECEIPT,
      ].includes(type),
      type: [
        REPORT_TYPES.INCOME,
        REPORT_TYPES.IPD_DISCHARGE,
        REPORT_TYPES.INSTALLMENT,
        REPORT_TYPES.DAILY_CASH,
        REPORT_TYPES.DAILY_COVERAGE,
        REPORT_TYPES.DAILY_APPROVED_UNPERFORMED,
        REPORT_TYPES.DAILY_APPROVED,
        REPORT_TYPES.APPROVAL_CODE,
        REPORT_TYPES.MEDICAL_FEE,
        REPORT_TYPES.DEPOSIT_REPORT,
        REPORT_TYPES.USED_DEPOSIT_REPORT,
        REPORT_TYPES.RECEIPT_RETURN_REPORT,
      ].includes(type),
      shift: [
        REPORT_TYPES.DAILY_CASH,
        REPORT_TYPES.DAILY_COVERAGE,
        REPORT_TYPES.DAILY_APPROVED_UNPERFORMED,
        REPORT_TYPES.DAILY_APPROVED,
        REPORT_TYPES.APPROVAL_CODE,
        REPORT_TYPES.MEDICAL_FEE,
        REPORT_TYPES.REMITTANCE,
        REPORT_TYPES.RECEIPT_RETURN_REPORT,
      ].includes(type),
      station: [
        REPORT_TYPES.DAILY_CASH,
        REPORT_TYPES.DAILY_COVERAGE,
        REPORT_TYPES.DAILY_APPROVED_UNPERFORMED,
        REPORT_TYPES.DAILY_APPROVED,
        REPORT_TYPES.APPROVAL_CODE,
        REPORT_TYPES.MEDICAL_FEE,
        REPORT_TYPES.RECEIPT_RETURN_REPORT,
      ].includes(type),
      receipt: [REPORT_TYPES.DAILY_CASH, REPORT_TYPES.DAILY_COVERAGE].includes(type),
      staff: [REPORT_TYPES.CASHIER_WORK].includes(type),
      hn: [REPORT_TYPES.DEPOSIT_REPORT, REPORT_TYPES.USED_DEPOSIT_REPORT].includes(type),
    });

    if (type === REPORT_TYPES.INCOME) {
      setTypeReport("DAILY");
    }
  };

  const handleChangeDate = (date: any, typeDate: any) => {
    if (typeDate === "start") {
      if (
        type === REPORT_TYPES.INCOME ||
        type === REPORT_TYPES.DAILY_UNPAID ||
        type === REPORT_TYPES.IPD_DISCHARGE ||
        type === REPORT_TYPES.INSTALLMENT
      ) {
        setStartDate(date);
      } else {
        setStartDate(date);

        props.runSequence({
          sequence: "BillingHistory",
          action: [
            REPORT_TYPES.CASHIER_WORK,
            REPORT_TYPES.DEPOSIT_REPORT,
            REPORT_TYPES.USED_DEPOSIT_REPORT,
          ].includes(type)
            ? "get_bilUser"
            : "get_bilStationLog",
          station: station === 0 ? "" : station,
          start_date: date,
          end_date: (
            [
              REPORT_TYPES.DRAWER_CASH,
              REPORT_TYPES.DRAWER_COVERAGE,
              REPORT_TYPES.REMITTANCE,
              REPORT_TYPES.USED_DEPOSIT_REPORT,
            ] as ReportTypesValues[]
          ).includes(type)
            ? date
            : endDate,
          type: type,
        });
      }
    } else if (typeDate === "end") {
      if (
        type === REPORT_TYPES.INCOME ||
        type === REPORT_TYPES.DAILY_UNPAID ||
        type === REPORT_TYPES.IPD_DISCHARGE ||
        type === REPORT_TYPES.INSTALLMENT
      ) {
        setEndDate(date);
      } else {
        setEndDate(date);

        props.runSequence({
          sequence: "BillingHistory",
          action: "get_bilStationLog",
          station: station === 0 ? "" : station,
          start_date: startDate,
          end_date: date,
          type: type,
        });
      }
    }
  };

  const handleChangeStation = (data: any) => {
    setStation(data);

    props.runSequence({
      sequence: "BillingHistory",
      action: [
        REPORT_TYPES.CASHIER_WORK,
        REPORT_TYPES.DEPOSIT_REPORT,
        REPORT_TYPES.USED_DEPOSIT_REPORT,
      ].includes(type)
        ? "get_bilUser"
        : "get_bilStationLog",
      station: data === 0 ? "" : data,
      start_date: startDate,
      end_date: (
        [
          REPORT_TYPES.DRAWER_CASH,
          REPORT_TYPES.DRAWER_COVERAGE,
          REPORT_TYPES.REMITTANCE,
          REPORT_TYPES.USED_DEPOSIT_REPORT,
        ] as ReportTypesValues[]
      ).includes(type)
        ? startDate
        : endDate,
      type: type,
    });
  };

  const handleCloseModal = () => {
    setStartDate(DEFAULT_DATE);
    setEndDate(DEFAULT_DATE);
    setStation(0);
    setShift(0);
    setCoverage(0);
    setPatientType(0);
    setReceiptStatus(0);
    setType("");
    setOpenMessage(false);
    setPayer("");
    setDischargeOnly(false);
    setStaff(null);
    setHN("");

    // clear ErrorMessage
    props.setProp(`errorMessage.${CARD_BILLING_REPORT}_print_report`, null);
  };

  const handleCloseModalTracking = () => {
    if (
      trackingStatus?.success ||
      trackingStatus?.status === TRACKING_STATUS.CANCELED ||
      trackingStatus?.status === TRACKING_STATUS.ERROR
    ) {
      setTracking(false);
      // clear trackingStatus
      props.runSequence({
        sequence: "BillingHistory",
        action: "clearTrackingStatus",
      });
    } else {
      props.runSequence({
        sequence: "BillingHistory",
        action: "set_tracking_print",
        task_id: trackingStatus?.task_id,
        pdf: trackingStatus?.pdf,
        status: "CANCEL",
      });
    }
  };

  const handlePrintReport = (pdf: boolean) => (e: any, data: any) => {
    if (startDate === "") {
      setOpenMessage(true);
    } else {
      setOpenMessage(false);

      props.runSequence({
        sequence: "BillingHistory",
        action: "print_report",
        buttonLoadKey: `${CARD_BILLING_REPORT}_${data.name}`,
        start_date: startDate,
        end_date: endDate,
        station: station === 0 ? undefined : station,
        station_log: shift === 0 ? undefined : shift,
        coverage: coverage === 0 ? undefined : coverage,
        payer: payer,
        discharge_only: dischargeOnly,
        patient_type: patientType === 0 ? undefined : patientType,
        receipt_status: receiptStatus === 0 ? undefined : receiptStatus,
        pdf,
        type: type,
        typeReport: typeReport,
        user: staff ? staff : undefined,
        card: CARD_BILLING_REPORT,
      });
    }
  };

  const handleDownLoadXLSX = (type: string) => {
    if (startDate === "") {
      setOpenMessage(true);
    } else {
      setOpenMessage(false);

      props.runSequence({
        sequence: "BillingHistory",
        card: CARD_BILLING_REPORT,
        action: "dowload_xlsx",
        buttonLoadKey: `${CARD_BILLING_REPORT}_${BUTTON_ACTIONS.XLSX}`,
        start_date: startDate,
        end_date: endDate,
        hn: hn,
        station: station === 0 ? undefined : station,
        user: staff ? staff : undefined,
        type: type,
      });
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <hr />
      <div style={{ width: "95%", display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {filteredReportButtonList
            .filter((i: any) => {
              return !(CONFIG.HIDE_BILLING_REPORT || [])
                ?.map((h: any) => REPORT_TYPES?.[h])
                .includes(i);
            })
            .map((name: string) => (
              <Button
                style={{ width: "20%", minWidth: "300px", margin: "10px 20px" }}
                onClick={(e) => reportShow(name)}
              >
                {name}
              </Button>
            ))}
        </div>
      </div>

      <Modal open={!!type} onClose={() => handleCloseModal()}>
        <div style={{ margin: "15px" }}>
          <div style={{ marginBottom: "15px", fontWeight: "bold" }}>{type}</div>

          {/* error message  */}
          {props.errorMessage?.[`${CARD_BILLING_REPORT}_print_report`] && (
            <Message color="red">
              {props.errorMessage?.[`${CARD_BILLING_REPORT}_print_report`]}
            </Message>
          )}

          {openMessage && (
            <div style={{ marginBottom: "15px" }}>
              {startDate === "" &&
              (
                [
                  REPORT_TYPES.DRAWER_COVERAGE,
                  REPORT_TYPES.DRAWER_CASH,
                  REPORT_TYPES.REMITTANCE,
                  REPORT_TYPES.CASHIER_WORK,
                ] as ReportTypesValues[]
              ).includes(type) ? (
                <Message color="red">กรุณาระบุค่าใน "วันที่"</Message>
              ) : (
                <Message color="red">กรุณาระบุค่าใน "เริ่มต้น"</Message>
              )}

              {endDate === "" &&
                !(
                  [
                    REPORT_TYPES.DRAWER_COVERAGE,
                    REPORT_TYPES.DRAWER_CASH,
                    REPORT_TYPES.IPD_DISCHARGE,
                    REPORT_TYPES.REMITTANCE,
                    REPORT_TYPES.CASHIER_WORK,
                  ] as ReportTypesValues[]
                ).includes(type) && <Message color="red">กรุณาระบุค่าใน "สิ้นสุด"</Message>}
            </div>
          )}

          {/* line 0 */}
          {type === REPORT_TYPES.INCOME && (
            <div
              style={{
                display: "flex",
                margin: "20px 0px",
              }}
            >
              <div style={{ width: "66%", display: "flex" }}>
                <label style={{ fontWeight: "bold" }}>
                  {intl.formatMessage({ id: "ประเภทรายงาน" })}
                </label>

                <div style={{ padding: "0px 10px" }}>
                  <Radio
                    label={intl.formatMessage({ id: "ประจำวัน" })}
                    checked={typeReport === "DAILY"}
                    onChange={() => setTypeReport("DAILY")}
                  />
                </div>
                <div style={{ padding: "0px 10px" }}>
                  <Radio
                    label={intl.formatMessage({ id: "จ่ายประจำเดือน" })}
                    checked={typeReport === "MONTH"}
                    onChange={() => setTypeReport("MONTH")}
                  />
                </div>
                <div style={{ padding: "0px 10px" }}>
                  <Radio
                    label={intl.formatMessage({ id: "ประจำปี" })}
                    checked={typeReport === "YEAR"}
                    onChange={() => setTypeReport("YEAR")}
                  />
                </div>
              </div>
            </div>
          )}

          {/* line 1 */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: startDateLabel(type) === "วันที่ค่าใช้จ่ายเริ่มต้น" ? "45%" : "40%",
              }}
            >
              <label style={{ fontWeight: "bold" }}>
                {/* {(
                  [
                    REPORT_TYPES.DRAWER_COVERAGE,
                    REPORT_TYPES.DRAWER_CASH,
                    REPORT_TYPES.CASHIER_WORK,
                    REPORT_TYPES.REMITTANCE,
                  ] as ReportTypesValues[]
                ).includes(type)
                  ? "วันที่"
                  : "เริ่มต้น"} */}
                {startDateLabel(type)}
              </label>
              {(startDateLabel(type) === "ช่วงวันที่" ||
                type === REPORT_TYPES.USED_DEPOSIT_REPORT) && <div style={{ color: "red" }}>*</div>}

              <DateTextBox
                style={{ margin: "0px 10px" }}
                value={startDate}
                onChange={(date: any) => handleChangeDate(date, "start")}
              ></DateTextBox>
            </div>

            <div
              style={{
                width: startDateLabel(type) === "วันที่ค่าใช้จ่ายเริ่มต้น" ? "35%" : "40%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {(
                [
                  REPORT_TYPES.DRAWER_COVERAGE,
                  REPORT_TYPES.DRAWER_CASH,
                  REPORT_TYPES.CASHIER_WORK,
                  REPORT_TYPES.REMITTANCE,
                ] as ReportTypesValues[]
              ).includes(type) ? (
                <>
                  <label style={{ fontWeight: "bold" }}>Station</label>
                  <Dropdown
                    fluid={true}
                    selection={true}
                    options={bilStationOptions}
                    style={{ width: "100%", margin: "0px 10px" }}
                    value={station}
                    onChange={(e: any, data: any) => handleChangeStation(data.value)}
                  ></Dropdown>
                </>
              ) : (
                <div
                  style={{
                    display:
                      type === REPORT_TYPES.IPD_DISCHARGE ||
                      type === REPORT_TYPES.USED_DEPOSIT_REPORT
                        ? "none"
                        : "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ fontWeight: "bold" }}>
                    {([REPORT_TYPES.DEPOSIT_REPORT] as ReportTypesValues[]).includes(type)
                      ? "ถึง"
                      : intl.formatMessage({ id: "สิ้นสุด" })}
                  </label>
                  <DateTextBox
                    style={{ margin: "0px 10px" }}
                    value={endDate}
                    onChange={(date: any) => handleChangeDate(date, "end")}
                  ></DateTextBox>
                </div>
              )}
            </div>

            <div style={{ width: "20%" }}>
              {(
                [
                  REPORT_TYPES.DEPOSIT_REPORT,
                  REPORT_TYPES.USED_DEPOSIT_REPORT,
                ] as ReportTypesValues[]
              ).includes(type) ? (
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={() => handleDownLoadXLSX(type)}
                  // data
                  paramKey={`${CARD_BILLING_REPORT}_${BUTTON_ACTIONS.XLSX}`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`${CARD_BILLING_REPORT}_${BUTTON_ACTIONS.XLSX}`]
                  }
                  // config
                  name={BUTTON_ACTIONS.XLSX}
                  title="ดาวน์โหลด xlsx"
                  color="green"
                  style={{ width: "100%" }}
                />
              ) : (
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={handlePrintReport(true)}
                  // data
                  paramKey={`${CARD_BILLING_REPORT}_${BUTTON_ACTIONS.PREVIEW}`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`${CARD_BILLING_REPORT}_${BUTTON_ACTIONS.PREVIEW}`]
                  }
                  // config
                  name={BUTTON_ACTIONS.PREVIEW}
                  title="Preview"
                  color="yellow"
                  style={{ width: "100%" }}
                />
              )}
            </div>
          </div>

          {/* line 2 */}
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              alignItems: "center",
              ...(!displayFilter.hn && {
                justifyContent: "space-around",
              }),
            }}
          >
            {displayFilter.station ? (
              <>
                <div
                  style={{
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <label style={{ fontWeight: "bold" }}>Station</label>
                  <Dropdown
                    fluid={true}
                    selection={true}
                    options={bilStationOptions}
                    style={{ width: "100%", margin: "0px 10px" }}
                    value={station}
                    onChange={(e: any, data: any) => handleChangeStation(data.value)}
                  ></Dropdown>
                </div>

                <div
                  style={{
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: (
                        [
                          REPORT_TYPES.RECEIPT_RETURN_REPORT,
                          REPORT_TYPES.APPROVAL_CODE,
                        ] as ReportTypesValues[]
                      ).includes(type)
                        ? "none"
                        : "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <label style={{ fontWeight: "bold" }}>Coverage</label>
                    <Dropdown
                      fluid={true}
                      selection={true}
                      options={bilCoverageOptions}
                      style={{ width: "100%", margin: "0px 10px" }}
                      value={coverage}
                      onChange={(e: any, data: any) => setCoverage(data.value)}
                    ></Dropdown>
                  </div>
                </div>
              </>
            ) : displayFilter.hn ? (
              <>
                <div
                  style={{
                    width: "6%",
                  }}
                >
                  <label style={{ fontWeight: "bold" }}>HN</label>
                </div>
                <div
                  style={{
                    width: "27%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input
                    fluid={true}
                    style={{ width: "100%", margin: "0px 10px" }}
                    value={hn}
                    onChange={(e: any, data: any) => setHN(data.value)}
                    placeholder="ระบุหมายเลข HN"
                  ></Input>
                </div>

                <div
                  style={{
                    width: "9%",
                  }}
                >
                  <label style={{ fontWeight: "bold" }}>จุดเก็บเงิน</label>
                </div>
                <div
                  style={{
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Dropdown
                    fluid={true}
                    selection={true}
                    options={bilStationOptions}
                    style={{ width: "100%", margin: "0px 10px" }}
                    value={station}
                    onChange={(e: any, data: any) => handleChangeStation(data.value)}
                  ></Dropdown>
                </div>
              </>
            ) : (
              <div style={{ width: "80%", display: "flex", alignItems: "center" }}>
                {displayFilter.coverage ? (
                  <>
                    <label style={{ fontWeight: "bold" }}>Coverage</label>
                    <Dropdown
                      fluid={true}
                      selection={true}
                      options={bilCoverageOptions}
                      style={{ width: "100%", margin: "0px 10px" }}
                      value={coverage}
                      onChange={(e: any, data: any) => setCoverage(data.value)}
                    ></Dropdown>
                  </>
                ) : displayFilter.staff ? (
                  <>
                    <label style={{ fontWeight: "bold", minWidth: "max-content" }}>
                      เจ้าหน้าที่
                    </label>

                    {/* <SearchBoxDropdown
                      onEvent={props.onEvent}
                      // config
                      type="User"
                      id={`1`}
                      fluid={true}
                      useWithKey={true}
                      limit={20}
                      // Select
                      searchedItemListWithKey={props.searchedItemListWithKey}
                      selectedItem={staff}
                      setSelectedItem={handleSelectedItem}
                      // options
                      mapOptions={mapOptions}
                      // style
                      style={{ width: "100%" }}
                      boxStyle={{ width: "100%", paddingLeft: "0.75rem" }}
                    /> */}
                    <Dropdown
                      fluid={true}
                      search={true}
                      selection={true}
                      options={bilStationLogOptions}
                      style={{ width: "100%", margin: "0px 10px" }}
                      value={staff || ""}
                      onChange={(e: any, data: any) => setStaff(data.value)}
                    ></Dropdown>
                  </>
                ) : (
                  <>
                    <label style={{ fontWeight: "bold" }}>Shift</label>
                    <Dropdown
                      fluid={true}
                      selection={true}
                      options={[{ key: 0, value: 0, text: "ทุกกะ" }, ...bilStationLogOptions]}
                      style={{ width: "100%", margin: "0px 10px" }}
                      value={shift}
                      onChange={(e: any, data: any) => setShift(data.value)}
                    ></Dropdown>
                  </>
                )}
              </div>
            )}

            <div style={{ width: "20%" }}>
              {!displayFilter.hn && (
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  onClick={handlePrintReport(false)}
                  // data
                  paramKey={`${CARD_BILLING_REPORT}_${BUTTON_ACTIONS.PRINT}`}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[`${CARD_BILLING_REPORT}_${BUTTON_ACTIONS.PRINT}`]
                  }
                  // config
                  name={BUTTON_ACTIONS.PRINT}
                  title={intl.formatMessage({ id: "พิมพ์" })}
                  color="blue"
                  style={{ width: "100%" }}
                />
              )}
            </div>
          </div>

          {/* line 3 */}
          <div
            style={{
              display: displayFilter.type ? "flex" : "none",
              justifyContent: "space-around",
              marginTop: "10px",
            }}
          >
            {displayFilter.shift ? (
              <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                <label style={{ fontWeight: "bold" }}>Shift</label>
                <Dropdown
                  fluid={true}
                  selection={true}
                  options={[{ key: 0, value: 0, text: "ทุกกะ" }, ...bilStationLogOptions]}
                  style={{ width: "100%", margin: "0px 10px" }}
                  value={shift}
                  onChange={(e: any, data: any) => setShift(data.value)}
                ></Dropdown>
              </div>
            ) : displayFilter.hn ? (
              <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                <label style={{ fontWeight: "bold", minWidth: "max-content" }}>
                  {type === REPORT_TYPES.DEPOSIT_REPORT
                    ? `เจ้าหน้าที่รับมัดจำ`
                    : "เจ้าหน้าที่จุดเก็บเงิน"}
                </label>

                <Dropdown
                  fluid={true}
                  search={true}
                  selection={true}
                  options={bilStationLogOptions}
                  style={{ width: "100%", margin: "0px 10px" }}
                  value={staff || ""}
                  onChange={(e: any, data: any) => setStaff(data.value)}
                ></Dropdown>
              </div>
            ) : (
              <>
                <div
                  style={{
                    width: "38%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <>
                    <label style={{ fontWeight: "bold" }}>
                      {intl.formatMessage({ id: "ประเภทผู้ป่วย" })}
                    </label>
                    <Dropdown
                      fluid={true}
                      selection={true}
                      options={BILL_PATIENT_TYPE}
                      style={{ width: "60%", margin: "0px 10px" }}
                      value={patientType}
                      onChange={(e: any, data: any) => setPatientType(data.value)}
                    ></Dropdown>
                  </>
                </div>

                <div
                  style={{
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: type === REPORT_TYPES.INSTALLMENT ? "none" : "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={dischargeOnly}
                      style={{ marginRight: "10px" }}
                      onChange={(v: any, { checked }: any) => setDischargeOnly(checked)}
                    ></Checkbox>
                    <label style={{ fontWeight: "bold" }}>Discharge</label>
                  </div>
                </div>

                <div
                  style={{
                    width: "46%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: type === REPORT_TYPES.INSTALLMENT ? "none" : "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <label style={{ fontWeight: "bold" }}>Payer</label>
                    <div style={{ width: "100%", marginLeft: "10px" }}>
                      <SearchBox
                        vertical={true}
                        onEvent={props.onEvent}
                        setProp={props.setProp}
                        searchedItemList={props.searchedItemList}
                        selectedItem={payer}
                        setSelectedItem={(item: any) => {
                          setPayer(item);
                        }}
                        type="Payer"
                        placeholder={intl.formatMessage({
                          id: "กรอกชื่อผู้จ่ายเงินที่ต้องการค้นหา",
                        })}
                        toDisplay={(item: any) => `${item.name_with_id}`}
                        fluid={true}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {displayFilter.hn && <div style={{ width: "25%" }}></div>}
          </div>

          {/* line 4 */}
          <div
            style={{
              display: displayFilter.receipt ? "flex" : "none",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                width: "33%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <label style={{ fontWeight: "bold" }}>
                {intl.formatMessage({ id: "สถานะใบเสร็จ" })}
              </label>
              <Dropdown
                fluid={true}
                selection={true}
                options={BILL_STATUS}
                style={{ width: "60%", margin: "0px 10px" }}
                value={receiptStatus}
                onChange={(e: any, data: any) => setReceiptStatus(data.value)}
              ></Dropdown>
            </div>
          </div>
        </div>
      </Modal>

      {/* tracking */}
      <Modal open={tracking} size="mini">
        <div>
          <div
            style={{
              backgroundColor: trackingStatus?.success
                ? "green"
                : trackingStatus?.status === TRACKING_STATUS.CANCELED ||
                  trackingStatus?.status === TRACKING_STATUS.ERROR
                ? "red"
                : "#00B5AD",
              padding: "15px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {trackingStatus?.success
              ? "เตรียมเอกสารเสร็จสิ้น"
              : trackingStatus?.status === TRACKING_STATUS.CANCELED
              ? "ยกเลิก"
              : trackingStatus?.status === TRACKING_STATUS.ERROR
              ? "ERROR"
              : "กำลังเตรียมเอกสาร"}
          </div>

          <div style={{ padding: "15px" }}>
            {trackingStatus?.success ? (
              <div>
                <div>{intl.formatMessage({ id: "เตรียมเอกสารเสร็จสิ้น" })}</div>
              </div>
            ) : trackingStatus?.status === TRACKING_STATUS.CANCELED ? (
              <div>
                <div>{trackingStatus?.current_progress_description}</div>
              </div>
            ) : trackingStatus?.status === TRACKING_STATUS.ERROR ? (
              trackingStatus?.stack
            ) : (
              <div>
                <div>{intl.formatMessage({ id: "กรุณารอสักครู่" })}</div>
                <div style={{ padding: "10px 0px" }}>
                  For IT: task_id = {trackingStatus?.task_id}
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button
                basic
                color={trackingStatus?.success ? "green" : "red"}
                size="tiny"
                onClick={() => handleCloseModalTracking()}
              >
                {trackingStatus?.success ||
                trackingStatus?.status === TRACKING_STATUS.CANCELED ||
                trackingStatus?.status === TRACKING_STATUS.ERROR
                  ? "ตกลง"
                  : "ยกเลิก"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(CardBillingReport);
