import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardBillingQueueUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div>
        <div
          id="CardBillingQueueUX-div-1"
          style={{boxShadow: "none", padding: "10px"}}>
          
          <div
            className="ui form small"
            id="CardBillingQueueUX-div-2">
            {}
            <FormGroup
              id="CardBillingQueueUX-FormGroup-3"
              inline={true}>
              <FormField
                id="CardBillingQueueUX-FormField-5"
                inline={true}
                width={2}>
                <Checkbox
                  checked={props.billingFilterQueue?.checkedHN}
                  id="CardBillingQueueUX-Checkbox-17"
                  label="HN"
                  onChange={(e: any, {checked}) => props.onChangeChecked("checkedHN", checked)}>
                </Checkbox>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-6"
                inline={true}
                width={2}>
                <div
                  id="CardBillingQueueUX-div-33"
                  style={{width: "100%"}}>
                  {props.patientSearchBox}
                </div>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-29"
                inline={true}
                width={2}>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-8"
                inline={true}
                width={2}>
                <Checkbox
                  checked={props.billingFilterQueue?.checkedReceiptDate}
                  id="CardBillingQueueUX-Checkbox-18"
                  label="วันที่รับบริการ"
                  onChange={(e: any, {checked}) => props.onChangeChecked("checkedReceiptDate", checked)}>
                </Checkbox>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-9"
                inline={true}
                width={2}>
                <div
                  id="CardBillingQueueUX-div-34"
                  style={{width: "100%"}}>
                  {props.receiptStartDate}
                </div>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-10"
                inline={true}
                width={2}>
                <div
                  id="CardBillingQueueUX-div-35"
                  style={{width: "100%"}}>
                  {props.receiptEndDate}
                </div>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-37"
                inline={true}
                width={2}>
                <Checkbox
                  checked={props.billingFilterQueue?.checkedOverdue}
                  id="CardBillingQueueUX-Checkbox-38"
                  label="ผู้ป่วยที่มียอดค้างชำระ"
                  onChange={(e: any, {checked}) => props.onChangeChecked("checkedOverdue", checked)}>
                </Checkbox>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardBillingQueueUX-FormGroup-4"
              inline={true}>
              <FormField
                id="CardBillingQueueUX-FormField-11"
                inline={true}
                width={2}>
                <Checkbox
                  checked={props.billingFilterQueue?.checkedDivision}
                  id="CardBillingQueueUX-Checkbox-19"
                  label="หน่วยงาน"
                  onChange={(e: any, {checked}) => props.onChangeChecked("checkedDivision", checked)}>
                </Checkbox>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-12"
                inline={true}
                width={2}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardBillingQueueUX-Dropdown-24"
                  name="division"
                  onChange={props.onChangeOption}
                  options={props.masterOptions?.division}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.billingFilterQueue?.division}>
                </Dropdown>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-13"
                inline={true}>
                <Checkbox
                  checked={props.billingFilterQueue?.checkedStatus ?? false}
                  id="CardBillingQueueUX-Checkbox-20"
                  label="สถานะ"
                  onChange={(e: any, {checked}) => props.onChangeChecked("checkedStatus", checked)}
                  style={{ marginLeft: "40px" }}>
                </Checkbox>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-14"
                inline={true}
                width={3}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardBillingQueueUX-Dropdown-25"
                  multiple={true}
                  name="status"
                  onChange={props.onChangeOption}
                  options={props.encounterStatusOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.billingFilterQueue?.status}>
                </Dropdown>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-16"
                inline={true}
                width={2}>
                <Checkbox
                  checked={props.billingFilterQueue?.checkedOPD}
                  id="chk-bilOPD"
                  label="OPD"
                  onChange={(e: any, {checked}) => props.onChangeChecked("checkedOPD", checked)}>
                </Checkbox>
                <Checkbox
                  checked={props.billingFilterQueue?.checkedIPD}
                  id="chk-bilIPD"
                  label="IPD "
                  onChange={(e: any, {checked}) => props.onChangeChecked("checkedIPD", checked)}
                  style={{padding: "0px 10px"}}>
                </Checkbox>
                <Checkbox
                  checked={props.billingFilterQueue?.checkedTelemed}
                  id="CardBillingQueueUX-Checkbox-23"
                  label="Telemed"
                  onChange={(e: any, {checked}) => props.onChangeChecked("checkedTelemed", checked)}>
                </Checkbox>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-39"
                inline={true}
                width={2}>
                <Checkbox
                  checked={props.billingFilterQueue?.checkedCoverage}
                  id="CardBillingQueueUX-Checkbox-41"
                  label="สิทธิการรักษา"
                  onChange={(e: any, {checked}) => props.onChangeChecked("checkedCoverage", checked)}
                  style={{ marginLeft: "40px" }}>
                </Checkbox>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-40"
                inline={true}
                width={2}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  id="CardBillingQueueUX-Dropdown-42"
                  name="coverage"
                  onChange={props.onChangeOption}
                  options={props.masterOptions?.coverage}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.billingFilterQueue?.coverage}>
                </Dropdown>
              </FormField>
              <FormField
                id="CardBillingQueueUX-FormField-27"
                inline={true}
                width={1}>
                <Button
                  color="blue"
                  id="btn-bilSearch"
                  loading={props.loadingData}
                  onClick={props.onSearch}
                  style={ { marginLeft: "40px" }}>
                  ค้นหา
                </Button>
              </FormField>
            </FormGroup>
          </div>
          <div
            id="CardBillingQueueUX-div-31">
            
            <Table
              data={props.billingQueueList}
              getTrProps={props.selectedQueue}
              headers="HN,Encounter / AN,ประเภทผู้ป่วย,ชื่อ - นามสกุล,แพทย์,วันที่รับบริการ,หน่วยงาน,สถานะ,สิทธิการรักษา,"
              id="tb-bilQueueReq"
              keys="hn,number,type,patient_name,doctor_full_name,visit_date,division_name,status_label,coverage_name,action"
              minRows="10"
              pageSize="5"
              showPagination={false}
              style={{height: "65vh"}}
              widths="^90,^90,^90,^135,^135,^80,^110,^90,^120,150">
            </Table>
          </div>
          <div
            id="CardBillingQueueUX-div-36"
            style={{padding: "20px 10px"}}>
            {props.pagination}
          </div>
        </div>
      </div>
    )
}


export default CardBillingQueueUX

export const screenPropsDefault = {}

/* Date Time : Thu Mar 20 2025 15:31:22 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\", padding: \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-div-2"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormGroup-3"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormGroup-4"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-5"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-6"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-8"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-9"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-10"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-11"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-12"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-13"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-14"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-16"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Checkbox",
      "parent": 5,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.billingFilterQueue?.checkedHN"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-Checkbox-17"
        },
        "label": {
          "type": "value",
          "value": "HN"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, {checked}) => props.onChangeChecked(\"checkedHN\", checked)"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Checkbox",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.billingFilterQueue?.checkedReceiptDate"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-Checkbox-18"
        },
        "label": {
          "type": "value",
          "value": "วันที่รับบริการ"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, {checked}) => props.onChangeChecked(\"checkedReceiptDate\", checked)"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Checkbox",
      "parent": 11,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.billingFilterQueue?.checkedDivision"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-Checkbox-19"
        },
        "label": {
          "type": "value",
          "value": "หน่วยงาน"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, {checked}) => props.onChangeChecked(\"checkedDivision\", checked)"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Checkbox",
      "parent": 13,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.billingFilterQueue?.checkedStatus ?? false"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-Checkbox-20"
        },
        "label": {
          "type": "value",
          "value": "สถานะ"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, {checked}) => props.onChangeChecked(\"checkedStatus\", checked)"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"40px\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Checkbox",
      "parent": 16,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.billingFilterQueue?.checkedOPD"
        },
        "id": {
          "type": "value",
          "value": "chk-bilOPD"
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, {checked}) => props.onChangeChecked(\"checkedOPD\", checked)"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Checkbox",
      "parent": 16,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.billingFilterQueue?.checkedIPD"
        },
        "id": {
          "type": "value",
          "value": "chk-bilIPD"
        },
        "label": {
          "type": "value",
          "value": "IPD "
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, {checked}) => props.onChangeChecked(\"checkedIPD\", checked)"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Checkbox",
      "parent": 16,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.billingFilterQueue?.checkedTelemed"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-Checkbox-23"
        },
        "label": {
          "type": "value",
          "value": "Telemed"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, {checked}) => props.onChangeChecked(\"checkedTelemed\", checked)"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-Dropdown-24"
        },
        "name": {
          "type": "value",
          "value": "division"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeOption"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.billingFilterQueue?.division"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Dropdown",
      "parent": 14,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-Dropdown-25"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeOption"
        },
        "options": {
          "type": "code",
          "value": "props.encounterStatusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.billingFilterQueue?.status"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-27"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Button",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-bilSearch"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingData"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "style": {
          "type": "code",
          "value": " { marginLeft: \"40px\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-29"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-div-31"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 32,
      "name": "Table",
      "parent": 31,
      "props": {
        "data": {
          "type": "code",
          "value": "props.billingQueueList"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.selectedQueue"
        },
        "headers": {
          "type": "value",
          "value": "HN,Encounter / AN,ประเภทผู้ป่วย,ชื่อ - นามสกุล,แพทย์,วันที่รับบริการ,หน่วยงาน,สถานะ,สิทธิการรักษา,"
        },
        "id": {
          "type": "value",
          "value": "tb-bilQueueReq"
        },
        "keys": {
          "type": "value",
          "value": "hn,number,type,patient_name,doctor_full_name,visit_date,division_name,status_label,coverage_name,action"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"65vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "^90,^90,^90,^135,^135,^80,^110,^90,^120,150"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-div-33"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receiptStartDate"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-div-34"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receiptEndDate"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-div-35"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pagination"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-div-36"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"20px 10px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-37"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Checkbox",
      "parent": 37,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.billingFilterQueue?.checkedOverdue"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-Checkbox-38"
        },
        "label": {
          "type": "value",
          "value": "ผู้ป่วยที่มียอดค้างชำระ"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, {checked}) => props.onChangeChecked(\"checkedOverdue\", checked)"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-39"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-FormField-40"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Checkbox",
      "parent": 39,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.billingFilterQueue?.checkedCoverage"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-Checkbox-41"
        },
        "label": {
          "type": "value",
          "value": "สิทธิการรักษา"
        },
        "onChange": {
          "type": "code",
          "value": "(e: any, {checked}) => props.onChangeChecked(\"checkedCoverage\", checked)"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"40px\" }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Dropdown",
      "parent": 40,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardBillingQueueUX-Dropdown-42"
        },
        "name": {
          "type": "value",
          "value": "coverage"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeOption"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.coverage"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.billingFilterQueue?.coverage"
        }
      },
      "seq": 42,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardBillingQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
