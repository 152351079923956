import React, { useEffect, useState, useMemo } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { Icon, Message } from "semantic-ui-react";
import CardBillingSummaryUX from "./CardBillingSummaryUX";
import { useIntl } from "react-intl";

const CARD_BILLING_SUMMARY = "CardBillingSummary";

const CardBillingSummary = (props: any) => {
  const intl = useIntl();
  useEffect(() => {
    props.runSequence({
      sequence: "BillingHistory",
      action: "get_summary",
      restart: true,
    });

    let errorMessage = props.errorMessage;
    let { [`${CARD_BILLING_SUMMARY}_SAVE`]: e, ...restError } = errorMessage || {};
    props.setProp("errorMessage", restError);
  }, []);

  const receiptsLength = props?.billingHistorySequence?.reportSummary?.receipts.length;

  const formatComma = (val: any) =>
    !isNaN(parseFloat(val))
      ? parseFloat(val)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")
      : val;

  const billingSummaryData = useMemo(() => {
    return (props?.billingHistorySequence?.reportSummary?.receipts || []).map(
      (item: any, index: number) => ({
        ...item,
        payment_total: (
          <div
            style={
              receiptsLength !== index + 1
                ? { textAlign: "right" }
                : {
                    textAlign: "right",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }
            }
          >
            {formatComma(item?.payment_total)}
          </div>
        ),
        payment_cash: (
          <div
            style={
              receiptsLength !== index + 1
                ? { textAlign: "right" }
                : {
                    textAlign: "right",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }
            }
          >
            {formatComma(item?.payment_cash)}
          </div>
        ),
        payment_transfer: (
          <div
            style={
              receiptsLength !== index + 1
                ? { textAlign: "right" }
                : {
                    textAlign: "right",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }
            }
          >
            {formatComma(item?.payment_transfer)}
          </div>
        ),
        payment_credit: (
          <div
            style={
              receiptsLength !== index + 1
                ? { textAlign: "right" }
                : {
                    textAlign: "right",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }
            }
          >
            {formatComma(item?.payment_credit)}
          </div>
        ),
        payment_cheque: (
          <div
            style={
              receiptsLength !== index + 1
                ? { textAlign: "right" }
                : {
                    textAlign: "right",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }
            }
          >
            {formatComma(item?.payment_cheque)}
          </div>
        ),
        payment_coupon: (
          <div
            style={
              receiptsLength !== index + 1
                ? { textAlign: "right" }
                : {
                    textAlign: "right",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }
            }
          >
            {formatComma(item?.payment_coupon)}
          </div>
        ),
        payment_refund: (
          <div
            style={
              receiptsLength !== index + 1
                ? { textAlign: "right" }
                : {
                    textAlign: "right",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }
            }
          >
            {formatComma(item?.payment_refund)}
          </div>
        ),
      })
    );
  }, [props?.billingHistorySequence?.reportSummary]);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CardBillingSummaryUX
        style={{ zoom: "90%" }}
        stationSummary={props?.billingHistorySequence?.reportSummary}
        billingSummaryData={billingSummaryData}
        buttonRefresh={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "BillingHistory",
                action: "get_summary",
                buttonLoadKey: `${CARD_BILLING_SUMMARY}_REFRESH`,
              });
            }}
            // data
            paramKey={`${CARD_BILLING_SUMMARY}_REFRESH`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_BILLING_SUMMARY}_REFRESH`]}
            // config
            style={{ width: "100%" }}
            title={
              <div>
                <Icon name="refresh"></Icon>
                refresh
              </div>
            }
            color="blue"
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "BillingHistory",
                action: "print_summary",
                buttonLoadKey: `${CARD_BILLING_SUMMARY}_PRINT`,
                will_close: false,
                will_print: true,
              });
            }}
            // data
            paramKey={`${CARD_BILLING_SUMMARY}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_BILLING_SUMMARY}_PRINT`]}
            // config
            style={{ width: "100%" }}
            title={intl.formatMessage({ id: "พิมพ์ใบสรุปยอดระหว่างกะ" })}
            color="blue"
          />
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "BillingHistory",
                action: "print_summary",
                buttonLoadKey: `${CARD_BILLING_SUMMARY}_SAVE`,
                will_close: true,
                will_print: false,
              });
            }}
            // data
            paramKey={`${CARD_BILLING_SUMMARY}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_BILLING_SUMMARY}_SAVE`]}
            // config
            style={{ width: "100%" }}
            title={intl.formatMessage({ id: "ปิดกะและพิมพ์ใบสรุปยอด" })}
            color="green"
          />
        }
        errorMessage={
          <div>
            {props.errorMessage?.[`${CARD_BILLING_SUMMARY}_SAVE`] != null && (
              <Message color="red">
                <li>{props.errorMessage?.[`${CARD_BILLING_SUMMARY}_SAVE`]}</li>
              </Message>
            )}
          </div>
        }
        languageUX={props.languageUX}
      />
    </div>
  );
};

export default CardBillingSummary;
