import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Form,
  FormGroup,
  FormField,
  Button,
  Input
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardBillingDepositHistoryUX = (props: any) => {
    return(
      <div
        style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ display:  "flex", justifyContent: "space-between",  padding: "10px 10px 0px 10px"  }}>
          
          <div
            style={{ fontSize: "1.2rem", fontWeight: "bold",display: "flex", alignItems: "center" }}>
            ประวัติการใช้เงินมัดจำ
          </div>
          <div
            style={{ fontSize: "1.2rem", fontWeight: "bold",display: "flex", alignItems: "center" }}>
            
          </div>
        </div>
        <div
          style={{padding: "0px 10px 10px 10px", display: props.viewMode ? "none" : "" }}>
          
          <hr>
          </hr>
        </div>
        <div>
          
          <Form>
            <FormGroup
              inline={true}
              style={{ display: "flex", margin: "10px 15px"  }}>
              <FormField>
                <div>
                  รหัสเอกสารเงินมัดจำ
                </div>
              </FormField>
              <FormField>
                <div>
                  
                  <Input
                    icon="search"
                    name="receiptNo"
                    onChange={props.onChange}
                    placeholder="ระบุรหัสเอกสาร"
                    value={props.depositHistory?.receiptNo}>
                  </Input>
                </div>
              </FormField>
              <FormField>
                <div
                  style={{ marginLeft: "40px" }}>
                  วันที่
                </div>
              </FormField>
              <FormField>
                <div>
                  
                  <DateTextBox
                    onChange={props.onChangeStartDate}
                    value={props.startDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField>
                <div>
                  
                  <DateTextBox
                    onChange={props.onChangeEndDate}
                    value={props.endDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField>
                <div>
                  
                  <Button
                    color="blue"
                    onClick={props.onSearchDepositHistory}
                    size="small">
                    ค้นหา
                  </Button>
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{ fontSize: "1.2rem", fontWeight: "bold", display: "flex", justifyContent: "flex-end", margin: "5px 10px 0px"  }}>
          {`ยอดเงินมัดจำคงเหลือรวม ${props.depositHistory?.depositTotal || "0.00"} บาท`}
        </div>
        <div
          style={{ margin: "20px 10px 30px 10px" }}>
          
          <Table
            data={props.depositHistoryData}
            headers="วันที่-เวลาที่ชำระ,เลขที่ใบเสร็จ ที่ชำระด้วยเงินมัดจำ,รหัสเอกสารเงินมัดจำ,ประเภทการรักษา,ยอดเงินที่ตัดใช้,เจ้าหน้าที่ผู้รับชำระ,"
            keys="paymentDatetime,paymentReceipt,depositReceipt,encounterType,payment,paymentCashier,buttonPrint"
            minRows="15"
            showPagination={false}>
          </Table>
        </div>
      </div>
    )
}


export default CardBillingDepositHistoryUX

export const screenPropsDefault = {"by":"order","title":"MEDICAL FEE","titleDescription":"ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"}

/* Date Time : Thu Jan 16 2025 10:47:13 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flexDirection: \"column\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display:  \"flex\", justifyContent: \"space-between\",  padding: \"10px 10px 0px 10px\"  }"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px 10px 10px\", display: props.viewMode ? \"none\" : \"\" }"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการใช้เงินมัดจำ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\",display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\",display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "hr",
      "parent": 131,
      "props": {
      },
      "seq": 134,
      "void": true
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"20px 10px 30px 10px\" }"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 137,
      "name": "Table",
      "parent": 136,
      "props": {
        "data": {
          "type": "code",
          "value": "props.depositHistoryData"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "วันที่-เวลาที่ชำระ,เลขที่ใบเสร็จ ที่ชำระด้วยเงินมัดจำ,รหัสเอกสารเงินมัดจำ,ประเภทการรักษา,ยอดเงินที่ตัดใช้,เจ้าหน้าที่ผู้รับชำระ,"
        },
        "keys": {
          "type": "value",
          "value": "paymentDatetime,paymentReceipt,depositReceipt,encounterType,payment,paymentCashier,buttonPrint"
        },
        "minRows": {
          "type": "value",
          "value": "15"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Form",
      "parent": 135,
      "props": {
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormGroup",
      "parent": 138,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", margin: \"10px 15px\"  }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 140,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสเอกสารเงินมัดจำ"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "Button",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchDepositHistory"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 152,
      "name": "DateTextBox",
      "parent": 149,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 153,
      "name": "DateTextBox",
      "parent": 148,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"40px\" }"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "Input",
      "parent": 147,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "name": {
          "type": "value",
          "value": "receiptNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุรหัสเอกสาร"
        },
        "value": {
          "type": "code",
          "value": "props.depositHistory?.receiptNo"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "`ยอดเงินมัดจำคงเหลือรวม ${props.depositHistory?.depositTotal || \"0.00\"} บาท`"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\", display: \"flex\", justifyContent: \"flex-end\", margin: \"5px 10px 0px\"  }"
        }
      },
      "seq": 136,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardBillingDepositHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "by": "order",
    "title": "MEDICAL FEE",
    "titleDescription": "ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"
  },
  "width": 80
}

*********************************************************************************** */
