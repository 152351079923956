import React, { useEffect, useMemo, useState } from "react";

// UX
import CardBillingQueue from "react-lib/apps/HISV3/BIL/CardBillingQueue";
import CardBillingSummary from "react-lib/apps/HISV3/BIL/CardBillingSummary";
import CardBillingReport from "react-lib/apps/HISV3/BIL/CardBillingReport";
import CardReceiptNumber from "react-lib/apps/HISV3/BIL/CardReceiptNumber";
import CardReceiptGroupSetting from "react-lib/apps/HISV3/BIL/CardReceiptGroupSetting";

// ADM
import { MenuItem } from "../ADM/CardADM";
import { Label } from "semantic-ui-react";

// Common
import ModInfo from "react-lib/apps/common/ModInfo";

// Config
import CONFIG from "config/config";
import { useIntl } from "react-intl";

const CARD_NAME = "CardBilling_ReceiptCodeStatus";

const CardBilling = (props: any) => {
  const intl = useIntl();
  const [mode, setMode] = useState("queue");
  const [openModReceiptCodeStatus, setOpenModReceiptCodeStatus] = useState(false);

  // Use Effect
  useEffect(() => {
    // * Get Receipt Code เพื่อแสดงเลขที่ใบเสร็จ
    props.onEvent({
      message: "HandleGetReceiptCode",
      params: {
        card: CARD_NAME,
      },
    });
  }, [props.selectedDevice]);

  const stationName = useMemo(() => {
    const bilStation: Record<string, any>[] = props.masterData?.bilStation || [];

    const foundStation = bilStation.find((item) => item.device === props.selectedDevice?.id);

    return (foundStation?.station_name || "") as string;
  }, [props.masterData?.bilStation, props.selectedDevice]);

  const getReceiptCodeStatus = useMemo(() => {
    if (props.errorMessage?.CardBilling_ReceiptCodeStatus) {
      setOpenModReceiptCodeStatus(true);
    }

    return null;
  }, [props.errorMessage]);

  const handleCloseModal = () => {
    setOpenModReceiptCodeStatus(false);
    props.setProp(`errorMessage.${CARD_NAME}`, null);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: "10px",
        overflow: "auto",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "flex", flex: "1" }}>
          <MenuItem
            menu="queue"
            mode={mode}
            name={intl.formatMessage({ id: "รายการรอรับชำระ" })}
            setMode={setMode}
          />
          {!props.constanceConfig.BIL_AUTO_RUN_RECEIPT && (
            <MenuItem
              menu="receipt_number"
              mode={mode}
              name={intl.formatMessage({ id: "ตั้งเลขที่ใบเสร็จ" })}
              setMode={setMode}
            />
          )}
          {!props.constanceConfig.BIL_AUTO_RUN_RECEIPT && (
            <MenuItem
              menu="receipt_setting"
              mode={mode}
              name={intl.formatMessage({ id: "ตั้งค่าชุดใบเสร็จ" })}
              setMode={setMode}
            />
          )}
          <MenuItem
            menu="shift_balance"
            mode={mode}
            name={intl.formatMessage({ id: "สรุปยอดปิดกะ" })}
            setMode={setMode}
          />
          <MenuItem
            menu="billing_report"
            mode={mode}
            name={intl.formatMessage({ id: "รายงานทางการเงิน" })}
            setMode={setMode}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flex: "1",
          }}
        >
          <Label
            size="large"
            style={{
              backgroundColor: "#FDC38E",
              color: "black",
              fontWeight: "normal",
            }}
          >
            เลขที่ใบเสร็จปัจจุบัน :{" "}
            {typeof props.bilReceiptCodeDetail?.code === "undefined"
              ? "-"
              : props.bilReceiptCodeDetail?.code || "ยังไม่ได้ตั้งเลขที่ใบเสร็จ"}
          </Label>
          {/* <div>{intl.formatMessage({ id: "เลขที่ใบเสร็จปัจจุบัน:" })} </div> */}
        </div>
      </div>

      {mode === "queue" ? (
        <CardBillingQueue
          onEvent={props.onEvent}
          setProp={props.setProp}
          // Controller
          drugOrderQueueController={props.drugOrderQueueController}
          // options
          masterOptions={props.masterOptions}
          // data
          billingFilterQueue={props.billingFilterQueue}
          billingQueuePagination={props.billingQueuePagination}
          billingQueueList={props.billingQueueList}
          loading={props.loadingData}
          selectedDevice={props.selectedDevice}
          stationName={stationName}
          constanceConfig={props.constanceConfig}
          // CommonInterface
          forward={props?.forward}
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // Receipt Code
          bilReceiptCodeDetail={props.bilReceiptCodeDetail}
          languageUX={props.languageUX}
        />
      ) : mode === "receipt_number" ? (
        <CardReceiptNumber
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          // data
          bilReceiptCodeDetail={props.bilReceiptCodeDetail}
          stationName={stationName}
          constanceConfig={props.constanceConfig}
          languageUX={props.languageUX}
        />
      ) : mode === "receipt_setting" ? (
        <CardReceiptGroupSetting
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          bilReceiptCodeDetail={props.bilReceiptCodeDetail}
          selectedDevice={props.selectedDevice}
          masterData={props.masterData}
          buttonLoadCheck={props.buttonLoadCheck}
          receiptGroupSetting={props.receiptGroupSetting}
        />
      ) : mode === "shift_balance" ? (
        <CardBillingSummary
          billingHistorySequence={props.billingHistorySequence}
          runSequence={props.runSequence}
          buttonLoadCheck={props.buttonLoadCheck}
          setProp={props.setProp}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          languageUX={props.languageUX}
        />
      ) : mode === "billing_report" ? (
        <CardBillingReport
          setProp={props.setProp}
          onEvent={props.onEvent}
          // seq
          runSequence={props.runSequence}
          billingHistorySequence={props.billingHistorySequence}
          constanceConfig={props.constanceConfig}
          // CommonInterface
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          searchedItemList={props.searchedItemList}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // options
          masterOptions={props.masterOptions}
          languageUX={props.languageUX}
        />
      ) : (
        <></>
      )}

      <ModInfo
        open={openModReceiptCodeStatus}
        titleColor="red"
        titleName={intl.formatMessage({ id: "แจ้งเตือน" })}
        alertText={props.errorMessage?.CardBilling_ReceiptCodeStatus}
        buttonColor="red"
        btnText={intl.formatMessage({ id: "ปิด" })}
        onApprove={handleCloseModal}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default CardBilling;
