import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Form,
  FormGroup,
  FormField
} from 'semantic-ui-react'

const CardBillingSummaryUX = (props: any) => {
    return(
      <div
        style={{}}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem",padding: "10px 10px 0px 10px", display: "flex", justifyContent: "space-between"}}>
          รายงานสรุปยอดและปิดกะ
          <div>
            
          </div>
        </div>
        <div
          style={{ padding: "0px 10px 5px 10px"}}>
          
          <hr>
          </hr>
        </div>
        <div
          style={{ padding: "0px 10px"}}>
          {props.errorMessage}
        </div>
        <div
          style={{ margin: "0px 10px" }}>
          
          <Form>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={5}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <label
                    style={{ fontWeight: "bold", width: "30%" }}>
                    จุดรับชำระเงิน
                  </label>
                  <div
                    style={{ display: "flex", background: "#F1F1F1", padding: "5px", borderRadius: 4,  width: "100%", justifyContent: "center" }}>
                    {props.stationSummary?.station_name || "-"}
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <label
                    style={{ fontWeight: "bold", width: "100%" }}>
                    หมายเลขจุดรับเงิน
                  </label>
                  <div
                    style={{ display: "flex", background: "#F1F1F1", padding: "5px", borderRadius: 4,  width: "100%", justifyContent: "center" }}>
                    {props.stationSummary?.station_number || "-"}
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <label
                    style={{ fontWeight: "bold", width: "45%" }}>
                    ชื่อเจ้าหน้าที่ผู้เปิดกะ
                  </label>
                  <div
                    style={{ display: "flex", background: "#F1F1F1", padding: "5px", borderRadius: 4,  width: "100%", justifyContent: "center" }}>
                    {props.stationSummary?.opened_user_name || "-"}
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <label
                    style={{ fontWeight: "bold", width: "70%" }}>
                    วันเวลาที่เปิดกะ
                  </label>
                  <div
                    style={{ display: "flex", background: "#F1F1F1", padding: "5px", borderRadius: 4,  width: "100%", justifyContent: "center" }}>
                    {props.stationSummary?.opened_time || "-"}
                  </div>
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{ padding: "5px 10px 0px 10px"}}>
          
          <Table
            data={props.billingSummaryData}
            headers="วันที่-เวลา,เลขที่เอกสาร,HN,ชื่อ - นามสกุล,สถานะ,ยอดที่รับชำระ / ยอดคืนผู้ป่วย,เงินสด,เงินโอน,บัตร,เช็ค,คูปอง,คืนเงิน"
            id="tb-billingSummaryData"
            keys="receipt_date_text,receipt_no,patient_hn,patient_name,receipt_status,payment_total,payment_cash,payment_transfer,payment_credit,payment_cheque,payment_coupon,payment_refund"
            minRows={1}
            showPagination={false}
            style={{height: "450px",  zoom: "87%", margin: "0px 10px"}}
            widths=",,100,300,,200,,,,,,">
          </Table>
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end", margin: "10px"}}>
          
          <div>
            {props.buttonRefresh}
          </div>
          <div
            id="btn-printShiftReport"
            style={{ margin: "0px 10px" }}>
            {props.buttonPrint}
          </div>
          <div
            id="btn-closeShiftReport">
            {props.buttonSave}
          </div>
        </div>
        <div
          style={{width: "100%", height: "200px"}}>
          
        </div>
      </div>
    )
}


export default CardBillingSummaryUX

export const screenPropsDefault = {"debug":true,"showSupplyList":false}

/* Date Time : Wed Jan 08 2025 14:53:20 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานสรุปยอดและปิดกะ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",padding: \"10px 10px 0px 10px\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"200px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 10px 5px 10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "hr",
      "parent": 87,
      "props": {
      },
      "seq": 88,
      "void": true
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", margin: \"10px\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRefresh"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "id": {
          "type": "value",
          "value": "btn-printShiftReport"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "id": {
          "type": "value",
          "value": "btn-closeShiftReport"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px 10px 0px 10px\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 94,
      "name": "Table",
      "parent": 93,
      "props": {
        "data": {
          "type": "code",
          "value": "props.billingSummaryData"
        },
        "headers": {
          "type": "value",
          "value": "วันที่-เวลา,เลขที่เอกสาร,HN,ชื่อ - นามสกุล,สถานะ,ยอดที่รับชำระ / ยอดคืนผู้ป่วย,เงินสด,เงินโอน,บัตร,เช็ค,คูปอง,คืนเงิน"
        },
        "id": {
          "type": "value",
          "value": "tb-billingSummaryData"
        },
        "keys": {
          "type": "value",
          "value": "receipt_date_text,receipt_no,patient_hn,patient_name,receipt_status,payment_total,payment_cash,payment_transfer,payment_credit,payment_cheque,payment_coupon,payment_refund"
        },
        "minRows": {
          "type": "code",
          "value": "1"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"450px\",  zoom: \"87%\", margin: \"0px 10px\"}"
        },
        "widths": {
          "type": "value",
          "value": ",,100,300,,200,,,,,,"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessage"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 10px\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Form",
      "parent": 96,
      "props": {
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormGroup",
      "parent": 97,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.stationSummary?.station_name || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", background: \"#F1F1F1\", padding: \"5px\", borderRadius: 4,  width: \"100%\", justifyContent: \"center\" }"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.stationSummary?.station_number || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", background: \"#F1F1F1\", padding: \"5px\", borderRadius: 4,  width: \"100%\", justifyContent: \"center\" }"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "code",
          "value": "props.stationSummary?.opened_user_name || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", background: \"#F1F1F1\", padding: \"5px\", borderRadius: 4,  width: \"100%\", justifyContent: \"center\" }"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "code",
          "value": "props.stationSummary?.opened_time || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", background: \"#F1F1F1\", padding: \"5px\", borderRadius: 4,  width: \"100%\", justifyContent: \"center\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "จุดรับชำระเงิน"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\" }"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "label",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเลขจุดรับเงิน"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"100%\" }"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "label",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อเจ้าหน้าที่ผู้เปิดกะ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"45%\" }"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "label",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเวลาที่เปิดกะ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"70%\" }"
        }
      },
      "seq": 114,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardBillingSummaryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "debug": true,
    "showSupplyList": false
  },
  "width": 80
}

*********************************************************************************** */
